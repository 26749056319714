.select-input {
	color: var(--text-secondary);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	&::placeholder {
		color: var(--text-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		opacity: 1;
	}
}
