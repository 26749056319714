.trade-wallet-balances-table {
	&__scroll-wrap {
		padding-bottom: 20px;
	}

	&__scroll {
		padding: 0;
		max-height: 293px;
		overflow: auto;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 6px;
			background: var(--gray100);
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}
	}

	&__tr {
		display: grid;
		grid-template-columns: 1.4fr 0.8fr 0.6fr;
		padding: 15px 24px;
		border-bottom: 1px solid var(--gray100);
		align-items: center;
	}

	&__td {
		p {
			color: var(--gray800);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	&__coin {
		display: flex;
		align-items: center;
		gap: 10px;

		img {
			width: 42px;
			min-width: 42px;
			height: 42px;
			min-height: 42px;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: var(--gray800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;

		span {
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: none;
		}
	}

	&__actions {
		display: flex;
		flex-direction: row;
		gap: 8px;
		justify-content: flex-end;
	}
}
