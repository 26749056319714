.account-settings-section {
	margin-top: 24px;

	&__title {
		color: var(--Greyscale-Grey-800);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 10px;
	}
}
