.form {
	&--type2 {
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: var(--additional-white);
		padding: 24px;

		.form-body {
			width: 100%;
			max-width: 610px;
			border-radius: 10px;
			background: var(--primary-blue-50);
			margin: 0;
			padding: 20px;
		}

		.input--success .input-item {
			border: 1px solid var(--additional-white);
		}

		.input__name {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			margin-bottom: 8px;
		}

		.input-item {
			height: 44px;
			border-radius: 10px;
			background: var(--additional-white);
			border: 1px solid var(--additional-white);
			padding: 0 16px;
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				color: var(--greyscale-grey-200);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}

		.select {
			height: 44px;
			border: 1px solid var(--additional-white);
			border-radius: 10px;
			background: var(--additional-white);

			&.active {
				.select__current-arrow {
					transform: rotate(180deg);
				}
			}
		}

		.coin--type3 .coin__text {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding: 0;
		}
	}

	&--type3 {
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: var(--additional-white);
		padding: 24px;

		.form-body {
			width: 100%;
			max-width: 100%;
			border-radius: 10px;
			background: var(--primary-blue-50);
			margin: 0;
			padding: 20px;
		}

		.input--success .input-item {
			border: 1px solid var(--additional-white);
		}

		.input__name {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			margin-bottom: 8px;
		}

		.input-item {
			height: 44px;
			border-radius: 10px;
			background: var(--additional-white);
			border: 1px solid var(--additional-white);
			padding: 0 16px;
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				color: var(--greyscale-grey-200);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}

		.select {
			height: 44px;
			border: 1px solid var(--additional-white);
			border-radius: 10px;
			background: var(--additional-white);

			&.active {
				.select__current-arrow {
					transform: rotate(180deg);
				}
			}
		}

		.coin--type3 .coin__text {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding: 0;
		}
	}

	&__notes {
		border-radius: 4px;
		background: var(--transparent-red);
		padding: 8px 12px 8px 10px;
		width: 100%;
		max-width: 359px;
		display: flex;
		gap: 6px;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: -10px;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-right: 10px solid var(--transparent-red);

			@media only screen and (max-width: 767.98px) {
				display: none;
			}
		}

		svg {
			width: 20px;
			min-width: 20px;
			height: 20px;
		}

		p {
			color: var(--Greyscale-Grey-800);
			font-size: 14px;
			font-weight: 400;
			line-height: 150%;
			letter-spacing: 0;
		}
	}

	.input--error .input-item,
	.input--error .textarea {
		border: 1px solid var(--additional-error);
		color: var(--blue600);
	}

	.input--success .input-item {
		border: 1px solid var(--greyscale-grey-100);
	}

	.input-wrap {
		&--col-2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
		}
	}
}
