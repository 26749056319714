.successful-info {
	margin-bottom: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 4px;
	background: var(--gray300);

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
		color: var(--text-secondary);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		span {
			color: var(--gray800);
			text-align: right;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 180%; /* 21.6px */
		}
	}
}
