.wallet-operations-item {
	max-width: 100%;

	+ .wallet-operations-item {
		margin-top: 18px;
	}

	&.relative {
		position: relative;
	}

	&--border-top {
		padding-top: 18px;
		border-top: 1px solid #c7e0ef;
	}

	&__title {
		color: var(--gray700);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-bottom: 12px;

		span {
			width: 28px;
			height: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: #fff;
			border: 0;
			color: var(--blue600);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	&:not(.wallet-operations-item--popup) {
		.select-block {
			margin-bottom: 0;
		}

		.select {
			border-radius: 8px;
			border: 0;
			background: #fff;
			height: 60px;

			&.active {
				.select__current-arrow {
					transform: rotate(180deg);
				}
			}
		}
	}

	.input-double-item {
		position: relative;
		display: block;

		.input-item {
			border-radius: 8px;
			height: 60px;
			padding: 8px 70px 8px 16px;
			border: 0;
			background: #fff;
		}

		.input-item-info {
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--gray300);
			border: 0;
			border-radius: 6px;
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			height: calc(100% - 16px);
			position: absolute;
			right: 8px;
			top: 8px;
			padding: 0 16px;
		}
	}

	.receive-amount {
		&__box {
			border-radius: 8px;
			background: #fff;
			padding: 8px 8px 8px 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 60px;
			margin-bottom: 0;
		}

		&__box-text {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		&__box-code {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			border-radius: 6px;
			background: var(--gray300);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 16px;
		}

		&__fees {
			margin-top: 12px;
			padding: 0 16px;
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		&__fees-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__fees-text {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		&__fees-price {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 10px;
			color: #212c63;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			span {
				color: #777e90;
			}
		}
	}

	.error-block {
		margin-bottom: 0;
		padding: 8px 10px;
		display: flex;
		align-items: flex-start;
		gap: 6px;
		border-radius: 4px;
		background: var(--transparent-red);
		position: relative;
		z-index: 2;

		&__wrap {
			margin-top: 12px;

			@media only screen and (min-width: 1024px) {
				margin-top: 0;
				position: absolute;
				top: 50%;
				left: calc(100% + 36px);
				width: 100%;
				max-width: 362px;
				transform: translateY(-50%);
			}

			&:before {
				@media only screen and (min-width: 1024px) {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: -8px;
					transform: translateY(-50%);
					z-index: 1;
					background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.3729 8.64399C1.29493 7.89715 0.755942 7.52373 0.755942 7C0.755942 6.47627 1.29493 6.10285 2.3729 5.35601L8.11101 1.38053C9.48894 0.425869 10.1779 -0.0514607 10.714 0.229285C11.25 0.51003 11.25 1.34819 11.25 3.02452L11.25 10.9755C11.25 12.6518 11.25 13.49 10.714 13.7707C10.1779 14.0515 9.48894 13.5741 8.11101 12.6195L2.3729 8.64399Z' fill='%23FEECEB'/%3E%3C/svg%3E%0A");
					width: 12px;
					min-width: 12px;
					height: 14px;
					min-height: 14px;
				}
			}
		}

		&__icon {
			min-width: 20px;
			width: 20px;
			height: 20px;
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99298 13.773C10.1588 13.773 10.2993 13.7169 10.4144 13.6047C10.5295 13.4925 10.5871 13.3535 10.5871 13.1876C10.5871 13.0218 10.531 12.8813 10.4188 12.7662C10.3066 12.6511 10.1676 12.5935 10.0018 12.5935C9.83591 12.5935 9.69542 12.6496 9.58031 12.7618C9.4652 12.874 9.40765 13.013 9.40765 13.1789C9.40765 13.3447 9.46374 13.4852 9.57594 13.6003C9.68812 13.7154 9.82713 13.773 9.99298 13.773ZM9.45573 11.0615H10.539V6.06148H9.45573V11.0615ZM10.0045 17.5807C8.9613 17.5807 7.9788 17.3833 7.05702 16.9886C6.13523 16.5939 5.32841 16.0508 4.63656 15.3592C3.9447 14.6677 3.40132 13.8615 3.00642 12.9408C2.61151 12.02 2.41406 11.0361 2.41406 9.98923C2.41406 8.94231 2.61142 7.96144 3.00615 7.0466C3.40087 6.13176 3.94399 5.32841 4.63552 4.63656C5.32706 3.9447 6.13322 3.40132 7.054 3.00642C7.97478 2.61151 8.95862 2.41406 10.0055 2.41406C11.0524 2.41406 12.0333 2.61142 12.9481 3.00615C13.863 3.40087 14.6663 3.94399 15.3582 4.63552C16.05 5.32706 16.5934 6.1316 16.9883 7.04913C17.3832 7.96665 17.5807 8.94702 17.5807 9.99023C17.5807 11.0335 17.3833 12.016 16.9886 12.9377C16.5939 13.8595 16.0508 14.6663 15.3592 15.3582C14.6677 16.05 13.8632 16.5934 12.9456 16.9883C12.0281 17.3832 11.0477 17.5807 10.0045 17.5807ZM9.99738 16.4974C11.8029 16.4974 13.3377 15.8654 14.6015 14.6015C15.8654 13.3377 16.4974 11.8029 16.4974 9.99738C16.4974 8.19182 15.8654 6.6571 14.6015 5.39321C13.3377 4.12932 11.8029 3.49738 9.99738 3.49738C8.19182 3.49738 6.6571 4.12932 5.39321 5.39321C4.12932 6.6571 3.49738 8.19182 3.49738 9.99738C3.49738 11.8029 4.12932 13.3377 5.39321 14.6015C6.6571 15.8654 8.19182 16.4974 9.99738 16.4974Z' fill='%23FE0000'/%3E%3C/svg%3E%0A");
			display: block;
		}

		p {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 21px */
		}
	}

	.deposit-address {
		&__text {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			gap: 0;
			word-break: break-all;
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0 16px;
			height: 60px;
			border-radius: 8px;
			background: #fff;
			position: relative;
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 6px;
			border: 1px solid #a8dbf9;
			background: #d4edfc;
			width: 30px;
			min-width: 30px;
			height: 30px;
			min-height: 30px;
			position: absolute;
			top: calc(50% - 15px);
			right: 16px;

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.12489 17.4218C4.74979 17.4218 4.43273 17.2923 4.1737 17.0333C3.91467 16.7742 3.78516 16.4572 3.78516 16.0821V5.67188H4.86847V16.0821C4.86847 16.1462 4.89518 16.2049 4.94859 16.2584C5.00201 16.3118 5.06077 16.3385 5.12489 16.3385H13.5351V17.4218H5.12489ZM7.7082 14.8385C7.33309 14.8385 7.01602 14.709 6.75699 14.4499C6.49798 14.1909 6.36847 13.8739 6.36847 13.4988V4.0116C6.36847 3.63651 6.49798 3.31944 6.75699 3.06042C7.01602 2.80139 7.33309 2.67188 7.7082 2.67188H15.1953C15.5704 2.67188 15.8875 2.80139 16.1465 3.06042C16.4056 3.31944 16.5351 3.63651 16.5351 4.0116V13.4988C16.5351 13.8739 16.4056 14.1909 16.1465 14.4499C15.8875 14.709 15.5704 14.8385 15.1953 14.8385H7.7082ZM7.7082 13.7552H15.1953C15.2594 13.7552 15.3182 13.7285 15.3716 13.675C15.4251 13.6216 15.4518 13.5629 15.4518 13.4988V4.0116C15.4518 3.94749 15.4251 3.88873 15.3716 3.83531C15.3182 3.7819 15.2594 3.75519 15.1953 3.75519H7.7082C7.64409 3.75519 7.58532 3.7819 7.53191 3.83531C7.47848 3.88873 7.45176 3.94749 7.45176 4.0116V13.4988C7.45176 13.5629 7.47848 13.6216 7.53191 13.675C7.58532 13.7285 7.64409 13.7552 7.7082 13.7552Z' fill='%230071AB'/%3E%3C/svg%3E%0A");
				width: 20px;
				min-width: 20px;
				height: 20px;
				min-height: 20px;
			}
		}
	}

	.input-item-text {
		position: absolute;
		right: 16px;
		top: -38px;
		transform: translateY(0);

		.button {
			color: var(--blue600);
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			border-radius: 4px;
			background: #fff;
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 8px;
		}
	}
	.text-field {
		min-height: 60px;
		border: 0;
		textarea {
			border: 0;
			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				border-radius: 2px;
				margin: 16px 3px 5px 3px;
				background: var(--Border-Line);
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background: var(--text-additional);
			}
		}
	}
}

