.progress-bar-container {
	width: 200px;
	height: 6px;
	border-radius: 10px;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	gap: 10px;
	background: var(--greyscale-grey-100);
}

.progress-bar {
	height: 100%;
	background-color: var(--primary-blue-500);
	border-radius: 4px;
	transition: width 0.3s ease;
	text-align: center;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.progress-text {
	margin: 0;
}

.progress-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-top: 40px;
	gap: 16px;
	p {
		color: var(--greyscale-grey-400);
		max-width: 240px;
		text-align: center;
		font-size: 14px;
	}
}

.progress-bar-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
	span {
		color: var(--greyscale-grey-400);
	}
}

