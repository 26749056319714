.verification-item {
	flex-direction: column;
	display: flex;

	.verification-pending {
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: 500px;
		p {
			color: var(--greyscale-grey-800);
			font-size: 18px;
			max-width: 70%;
		}
		a {
			color: var(--primary-blue-400);
		}
	}
}

