.login {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 14px;
	border: 1px solid var(--gray400);
	background: var(--white500);
	padding: 24px;

	@media only screen and (min-width: 768px) {
		width: 50%;
	}

	@media only screen and (min-width: 1024px) {
		width: 100%;
		padding: 40px;
	}

	&__notes {
		padding-top: 24px;

		p {
			color: var(--text-secondary);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			a {
				color: var(--primary-blue-400);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				&:hover {
					color: var(--text-color-main);
				}
			}
		}
	}
}

.login-recaptcha {
	margin: 16px 0 30px 0;
}
