body.login-page {
	.Toastify__toast-container {
		@media only screen and (min-width: 768px) {
			position: absolute;
			top: 80px;
			left: calc(var(--login-banner-left) - 442px);
			right: auto;
			padding: 0;
		}
	}
}

.Toastify__toast--error {
	border-radius: 12px;
	background: #feeceb;
	min-width: 362px;
	padding: 16px;
	font-family: var(--font-sans);

	.Toastify__close-button {
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.8763 13.9974L10.0013 10.8724L13.1263 13.9974L14.0013 13.1224L10.8763 9.9974L14.0013 6.8724L13.1263 5.9974L10.0013 9.1224L6.8763 5.9974L6.0013 6.8724L9.1263 9.9974L6.0013 13.1224L6.8763 13.9974ZM10.0013 18.3307C8.86241 18.3307 7.78603 18.112 6.77214 17.6745C5.75825 17.237 4.87283 16.6398 4.11589 15.8828C3.35894 15.1259 2.76172 14.2405 2.32422 13.2266C1.88672 12.2127 1.66797 11.1363 1.66797 9.9974C1.66797 8.84462 1.88672 7.76129 2.32422 6.7474C2.76172 5.73351 3.35894 4.85156 4.11589 4.10156C4.87283 3.35156 5.75825 2.75781 6.77214 2.32031C7.78603 1.88281 8.86241 1.66406 10.0013 1.66406C11.1541 1.66406 12.2374 1.88281 13.2513 2.32031C14.2652 2.75781 15.1471 3.35156 15.8971 4.10156C16.6471 4.85156 17.2409 5.73351 17.6784 6.7474C18.1159 7.76129 18.3346 8.84462 18.3346 9.9974C18.3346 11.1363 18.1159 12.2127 17.6784 13.2266C17.2409 14.2405 16.6471 15.1259 15.8971 15.8828C15.1471 16.6398 14.2652 17.237 13.2513 17.6745C12.2374 18.112 11.1541 18.3307 10.0013 18.3307ZM10.0013 17.0807C11.9735 17.0807 13.6471 16.3898 15.0221 15.0078C16.3971 13.6259 17.0846 11.9557 17.0846 9.9974C17.0846 8.02517 16.3971 6.35156 15.0221 4.97656C13.6471 3.60156 11.9735 2.91406 10.0013 2.91406C8.04297 2.91406 6.37283 3.60156 4.99089 4.97656C3.60894 6.35156 2.91797 8.02517 2.91797 9.9974C2.91797 11.9557 3.60894 13.6259 4.99089 15.0078C6.37283 16.3898 8.04297 17.0807 10.0013 17.0807Z' fill='%23141414'/%3E%3C/svg%3E%0A");
		width: 20px;
		height: 20px;
		padding: 0;
		opacity: 1;
		position: absolute;
		top: 10px;
		right: 10px;

		svg {
			display: none;
		}
	}

	.toastify-component {
		flex-direction: column;
		align-items: flex-start;
	}

	.toastify-component__heading {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		img {
			margin-right: 6px;
		}

		p {
			color: var(--additional-error);
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			margin: 0;
			padding: 0;
		}
	}

	.toastify-component__content {
		padding: 0;
		margin-bottom: 16px;
		color: var(--gray800);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		a {
			color: #007bff;
			text-decoration: underline;
			cursor: pointer;
		}

		a:hover {
			color: #0056b3;
			text-decoration: none;
		}
	}

	.toastify-component__btn-light {
		color: var(--gray800);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.28px;
		padding: 2px 32px;
		height: 38px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		background: #fff;
		font-family: var(--font-sans);
		min-width: 120px;
	}
}
