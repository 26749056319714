.coin {
	&--type3 {
		.coin__icon {
			min-width: 42px;
			width: 42px;
			height: 42px;
		}

		.coin__text {
			color: var(--gray800);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding: 0;
		}

		.coin__text-more {
			padding-top: 4px;
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			&--type2 {
				padding-top: 4px;
				padding-left: 0;
			}
		}
	}

	&--type7 {
		align-items: center;
		gap: 10px;

		.coin__icon {
			min-width: 30px;
			width: 30px;
			height: 30px;
		}

		.coin__text-wrap {
			width: 100%;
			display: flex;
			justify-content: space-between;
			gap: 6px;
		}

		.coin__text {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.coin__text-more {
			text-transform: capitalize;
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-left: 6px;
		}
	}

	&--type8 {
		align-items: center;
		gap: 8px;
		flex-flow: row wrap;

		&.flex-row {
			flex-flow: row;
		}

		.coin__icon {
			min-width: 24px;
			width: 24px;
			height: 24px;
		}

		.coin__text {
			color: var(--gray800);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%; /* 24px */
		}

		.coin__text-more {
			text-transform: capitalize;
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			margin-left: 0;
			width: 100%;

			&--left-offset {
				margin-left: 6px;
			}
		}
	}

	&--type9 {
		align-items: center;
		gap: 10px;
		flex-direction: row;

		.coin__icon {
			min-width: 42px;
			width: 42px;
			height: 42px;
		}

		.coin__text {
			color: var(--greyscale-grey-800);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		.coin__text-more {
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	&--type10 {
		align-items: center;
		gap: 10px;
		flex-direction: row;

		.coin__icon {
			min-width: 20px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			overflow: hidden;
		}

		.coin__text {
			font-family: var(--font-sans);
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 180%; /* 25.2px */
		}

		.coin__text-more {
			text-transform: capitalize;
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-left: 6px;
		}
	}

	.coin__icon {
		border-radius: 50%;
		overflow: hidden;
	}

	.coin__text {
		font-family: var(--font-sans);

		&.coin__text--text-black {
			color: #000;
		}

		&.coin__text--fz-14 {
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 180%; /* 25.2px */
		}
	}
}
