.wallet-content-balance {
	padding: 25px 0 40px;
	margin: 0 -10px;
	border: 0;
	border-radius: 0;
	display: flex;
	gap: 0;

	@media only screen and (max-width: 1080px) {
		flex-direction: column;
		align-items: stretch;
		margin: -10px 0;
	}
}
