.instant-exchange-confirm-list {
	margin-bottom: 20px;
	padding: 10px;
	border-radius: 4px;
	background: var(--gray300);
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__item {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		border-bottom: 0;

		p {
			&:first-child {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			&:last-child {
				color: var(--gray800);
				text-align: right;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 180%; /* 21.6px */
			}
		}
	}
}
