.setting-content {
	padding: 24px;
	width: 100%;
	max-width: 100%;
	margin: 0;
	border-radius: 14px;
	border: 1px solid var(--greyscale-grey-100);
	background: var(--additional-white);

	@media only screen and (max-width: 767.98px) {
		padding: 16px;
	}

	/*@media only screen and (min-width: 768px) {
		margin: 0 0 250px 0;
	}*/

	&__title {
		color: var(--greyscale-grey-800);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 26px;
	}

	&__badge {
		border-radius: 20px;
		width: 79px;
		height: 27px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 20px;

		&--green {
			color: var(--additional-green);
			border: 0.8px solid var(--additional-green);
			background: var(--transparent-green);
		}
	}
}
