.table-wrapper {
	border-radius: 14px;
	border: 1px solid var(--gray100);
	background: #fff;
	overflow: hidden;
}

.table-wrapper--with-y-scroll {
	border-radius: 14px 14px 0 0;
	overflow-y: hidden;
	overflow-x: auto;
}

