.profile-content {
	background: var(--gray300);

	@media only screen and (min-width: 760px) {
		padding: 40px;
	}

	@media only screen and (min-width: 1200px) {
		padding: 40px 40px 40px 30px;
	}

	.instant-trade-section {
		padding: 0;
	}
}
