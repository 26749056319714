.instant-trade {
	margin: 0;
	padding: 0;
	text-align: left;
	border-radius: 0;
	border: 0;
	background: none;

	&__box {
		padding: 24px;
		text-align: left;
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: #f1f7fc;

		&--balances {
			background: #fff;
			padding: 0;
		}
	}

	.instant-trade-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0;
		margin-bottom: 20px;
		padding-bottom: 24px;
		border-bottom: 1px solid #c7e0ef;

		&__text {
			color: var(--gray800);
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		&__status {
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			position: relative;
			border-radius: 4px;
			background: rgba(255, 255, 255, 0.64);
			padding: 6px 8px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 4px;

			&:before {
				content: '';
				display: block;
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}

			&--up {
				color: #16b06d;

				&:before {
					content: '';
					display: block;
					background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.72006 13.3122L2.02344 12.6156L7.03256 7.60651L9.88256 10.4565L14.3191 6.05311H11.8513V5.07812H15.9763V9.20311H15.0013V6.74973L9.88256 11.8497L7.03256 8.99973L2.72006 13.3122Z' fill='%2316B06D'/%3E%3C/svg%3E%0A");
				}
			}
		}
	}

	.instant-trade-form {
		margin: 0 0 26px 0;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0;

		&__balance {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 26px;

			p {
				color: var(--gray700);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
			}

			span {
				color: var(--gray800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%;
				text-transform: uppercase;
			}
		}

		.instant-trade-switch-button {
			padding: 0;
			text-align: center;
			display: block;
			height: 44px;
			margin: -18px 0;
			position: relative;
			z-index: 1;

			button {
				width: 44px;
				height: 44px;
				display: inline-flex;
				background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='22' fill='white' stroke='%23FAFAFA' stroke-width='4'/%3E%3Cpath d='M20.9516 18.3634L18.5551 20.7598L17.8038 20.0006L21.5006 16.3038L25.1974 20.0006L24.4461 20.7598L22.0496 18.3634L22.0318 18.3456V18.3707V24.7818H20.9694V18.3707V18.3456L20.9516 18.3634ZM22.8038 28.0005L23.5551 27.2413L25.9516 29.6378L25.9694 29.6556V29.6304V23.2194H27.0318V29.6304V29.6556L27.0496 29.6378L29.4461 27.2413L30.1974 28.0005L26.5006 31.6974L22.8038 28.0005Z' fill='%230071AB' stroke='%230071AB' stroke-width='0.0208333'/%3E%3C/svg%3E%0A");
				background-size: 100%;
			}
		}
	}

	.instant-trade-form-item {
		&__field {
			position: relative;

			.select__drop {
				width: 100%;
				max-width: 100%;

				&.active {
					display: block;
					z-index: 3;
				}
			}

			.input {
				margin-bottom: 0;
				border-radius: 8px 0 0 8px;
				background: #fff;
				padding: 10px;

				input {
					color: var(--gray800);
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					padding: 0;
					border: 0;
					height: auto;
					width: 100%;
				}
			}

			.select-block {
				margin: 0;
				padding: 0;
				z-index: 1;
			}

			.select {
				height: 100%;
				border: 0;
				border-radius: 0 8px 8px 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&.active {
					.select__current .select__current-arrow {
						transform: rotate(180deg);
					}
				}

				&__current {
					padding: 0;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					width: 100%;
					height: 100%;

					.coin {
						display: flex;
						align-items: center;
						gap: 10px;

						&__icon {
							position: relative;
							min-width: 30px;
							width: 30px;
							height: 30px;
							display: block;
						}

						&__text {
							color: var(--gray800);
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							text-transform: uppercase;
						}
					}

					.select__current-arrow {
						display: block;
						margin-left: 0;
						width: 20px;
						min-width: 20px;
						height: 20px;
						min-height: 20px;
						mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.1654 8.33594L10.3523 12.1491C10.157 12.3443 9.84041 12.3443 9.64514 12.1491L5.83203 8.33594' stroke='%23141414' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
						background-size: 20px 20px;
						background-repeat: no-repeat;
						background-position: center;
						background-color: var(--gray800);
						position: absolute;
						top: calc(50% - 10px);
						right: 10px;
						transition: var(--transition-base);
					}
				}
			}

			&--exchange {
				grid-template-columns: 1fr 110px;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			margin-bottom: 10px;

			p {
				color: var(--gray600);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		&__max {
			button {
				border-radius: 3px;
				background: #fff;
				box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
				padding: 3px 7px;
				color: var(--blue600);
				text-align: center;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
