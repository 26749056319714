.checkbox {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	font-family: Arial, sans-serif;
}

.checkbox label {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: Euclid Circular A;
	font-size: 14px;
	font-weight: 500;
	line-height: 25.2px;
	text-align: left;
	color: #141414;
}

.checkbox input[type="checkbox"] {
	opacity: 0;
	position: absolute;
	cursor: pointer;
	height: 20px;
	width: 20px;
}

.checkbox .custom-checkbox {
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #212C63;
	margin-right: 8px;
	transition: background-color 0.2s, border 0.2s;
}

.checkbox input[type="checkbox"]:checked + .custom-checkbox {
	background-color: #212C63; /* Restored background color */
	border: 2px solid #212C63; /* Restored border style */
}

.checkbox input[type="checkbox"]:checked + .custom-checkbox::after {
	content: "";
	position: absolute;
	left: 5px;
	top: 0;
	width: 5px;
	height: 10px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.checkbox label:hover .custom-checkbox {
	transform: scale(1.1);
	transition: transform 0.2s;
}

