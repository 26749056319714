.verification-upload-info {
	p {
		margin-bottom: 20px;
		color: var(--greyscale-grey-800);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	ul {
		display: block;
		padding: 0;
		margin: 0;
		list-style-type: none;

		li {
			color: var(--greyscale-grey-800);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
			position: relative;
			padding-left: 32px;

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='3' fill='%230071AB'/%3E%3C/svg%3E");
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
				position: absolute;
				top: 1px;
				left: 0;
			}

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}
	}
}
