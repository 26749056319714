.account-form {
	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__info-text {
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}

	&__verification-status {
		border-radius: 20px;
		border: 0.8px solid var(--additional-green);
		background: var(--transparent-green);
		height: 27px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 2px 24px;
		color: var(--additional-green);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&.green-bg {
			border-color: var(--additional-green);
			background-color: var(--transparent-green);
			color: var(--additional-green);
		}

		&.red-bg {
			border-color: var(--additional-error);
			background-color: var(--transparent-red);
			color: var(--additional-error);
		}

		&.yellow-bg {
			border-color: var(--additional-warning);
			background-color: var(--transparent-yellow);
			color: var(--additional-warning);
		}
	}

	.input__name,
	label {
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		margin-bottom: 8px;
	}

	.form-row {
		@media only screen and (min-width: 768px) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 16px;
		}
	}

	.form-group {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;
	}

	.form-control:not(.form-control--phone) {
		border-radius: 10px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		height: 44px;
		width: 100%;
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 22.4px */
		padding: 4px 16px;
	}

	.select {
		height: 44px;
		border: 1px solid var(--greyscale-grey-100);
		border-radius: 10px;

		&.active {
			.select__current-arrow {
				transform: rotate(180deg);
			}
		}

		.coin--type3 .coin__text {
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
			padding: 0;
			font-family: var(--font-sans);
		}
	}

	.react-tel-input-wrapper {
		.form-control {
			position: absolute;
			z-index: 10;
			left: 45px;
			top: 50%;
			padding: 0;
			width: 50px;
			height: 100%;
			border: none;
			pointer-events: none;
			background: transparent;
			transform: translateY(-50%);
			font-size: 14px;
			font-weight: 500;
			line-height: 180%; /* 25.2px */
			color: var(--greyscale-grey-800);
		}

		.react-tel-input {
			font-family: var(--font-sans);
			font-size: 14px;
			font-weight: 500;
			line-height: 180%; /* 25.2px */
			color: var(--greyscale-grey-800);
			position: absolute;
			top: 4px;
			left: 4px;
			z-index: 5;
			height: 36px;
			width: 117px;
		}

		.selected-flag {
			width: 100%;
			margin-top: 0;
			padding: 0 0 0 8px;
		}

		.flag-dropdown {
			width: 100%;
			border-radius: 4px;
			background: var(--primary-blue-50);
			border: 1px solid var(--primary-blue-50);
			height: 100%;
			top: 0;
			left: 0;
		}

		.react-tel-input .selected-flag {
			&.open {
				&:after {
					transform: rotate(180deg);
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 8.5px);
				right: 8px;
				width: 16px;
				min-width: 16px;
				height: 17px;
				min-height: 17px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M12.6654 6.5L8.35225 10.8131C8.15699 11.0084 7.84041 11.0084 7.64514 10.8131L3.33203 6.5' stroke='%23212C63' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
				transition: var(--transition-base);
			}

			.arrow {
				display: none;
			}

			.flag {
				//position: absolute;
				//top: 50%;
				//margin-top: -12px;
				//border-radius: 50%;
			}
		}

		.input {
			margin-bottom: 0;
		}

		.input--error .input-item,
		.input--error .textarea {
			border: 1px solid var(--greyscale-grey-100);
		}

		.input--success .input-item {
			border: 1px solid var(--greyscale-grey-100);
		}
	}

	.select-block {
		margin-bottom: 0;
	}

	.select__drop .select__drop-scroll {
		max-height: 180px;
	}
}
