.table-tabs {
	border-radius: 12px;
	background: #f1f7fc;
	padding: 4px;
	display: inline-flex;

	@media only screen and (max-width: 575.98px) {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	button {
		width: 232px;
		min-height: 36px;
		border-radius: 8px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: var(--Greyscale-Grey-700);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		background-color: transparent;
		transition: var(--transition-base);

		@media only screen and (max-width: 575.98px) {
			width: 100%;
		}

		&.is-active {
			background-color: var(--Additional-White);
			box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.1);
			color: var(--Primary-Blue-600);
			font-weight: 600;
		}
	}
}
