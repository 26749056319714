.searchfield input {
	border-left: 1px solid var(--border-main-color);
	display: block;
	width: calc(100% - 15px);
	height: 44px;
	padding: 0 40px !important;
	margin: 10px 0 10px;
	border: 1px solid var(--ui-input-border-color) !important;
	outline: none;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	-webkit-transition: all 0.15s ease;
	-o-transition: all 0.15s ease;
	transition: all 0.15s ease;
	border-radius: 8px !important;
}

.phone-dropdown {
	padding: 16px 8px;
	max-width: 273px;
	border: none;
	-webkit-box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
	box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
	border-radius: 8px;
	background: #ffffff;
	&::-webkit-scrollbar {
		width: 6px; /* ширина всей полосы прокрутки */
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: #b6b6b6;
	}
	&::-webkit-scrollbar-track {
		border-radius: 2px;
		margin: 80px 5px 10px 5px;
		background: #eeeeee;
	}
}

.phone-dropdown .country {
	display: flex;
	justify-content: space-between;
}

.react-tel-input .phone-dropdown .search-emoji {
	display: inline !important;
	visibility: hidden;
	position: absolute;
}

.react-tel-input .phone-dropdown .search-emoji::after {
	display: inline !important;
	visibility: visible;
	position: absolute;
	top: 22px;
	left: 20px;
	font-size: 15px;
	content: url(../../inline-svg/search.svg);
}

