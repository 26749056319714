.info-section {
	padding: 60px 0;

	&__box {
		padding: 24px;
		width: 100%;
		border-radius: 16px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);

		.select--per-page-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 16px;
		}
		.select {
			height: 32px;
		}
	}

	.container {
		width: 100%;
		max-width: 1180px;
		padding: 0 16px;
	}

	h1,
	h2 {
		color: var(--greyscale-grey-800);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 24px;
	}

	p {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 25.6px */
		margin-bottom: 20px;
	}
}

