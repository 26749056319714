.instant-exchange-confirm-info {
	margin-bottom: 24px;
	display: block;
	color: var(--text-secondary);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	p {
		a {
			color: var(--blue400);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
