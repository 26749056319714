.text-area-linkify__container {
	position: relative;
}
.text-area-linkify__textarea {
	border: 1px solid var(--greyscale-grey-100);
	border-radius: 10px;
	padding: 12px 16px;
	width: 100%;
	height: 96px;
	background: #fff;
	caret-color: rgb(0 0 0);
	color: transparent;
	position: relative;
	resize: vertical;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}
.text-area-linkify__mirror {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	user-select: none;
	overflow: hidden;
	pointer-events: none;
	color: var(--blue600);

	a {
		pointer-events: auto;
		color: var(--blue400);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
