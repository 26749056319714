.verification-acount-info {
	margin-top: 30px;
	border-top: 0;
	padding: 16px;
	border-radius: 4px;
	background: #fff5da;

	&__heading {
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 16px;

		&:before {
			content: '';
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0008 17.8453H4.95084C2.05917 17.8453 0.850839 15.7786 2.25084 13.2536L4.85084 8.57031L7.30084 4.17031C8.78417 1.49531 11.2175 1.49531 12.7008 4.17031L15.1508 8.57865L17.7508 13.262C19.1508 15.787 17.9342 17.8536 15.0508 17.8536H10.0008V17.8453Z' stroke='%23FFB500' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 7.5V11.6667' stroke='%23FFB500' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.99609 14.1641H10.0036' stroke='%23FFB500' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			width: 20px;
			min-width: 20px;
			height: 20px;
			min-height: 20px;
		}
	}

	ul {
		display: block;
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			padding-left: 32px;
			position: relative;

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='3' fill='%23141414'/%3E%3C/svg%3E");
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
				position: absolute;
				top: -2px;
				left: 0;
			}

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}
	}
}
