.payment-methods-item {
	&--text-right {
		@media only screen and (min-width: 920px) {
			text-align: right;
		}
	}

	&__curr {
		display: flex;
		align-items: center;
		gap: 8px;

		img,
		svg {
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
		}
	}

	&__note {
		margin-top: 6px;
		color: var(--greyscale-grey-500);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}

	p {
		color: var(--gray600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		margin-bottom: 10px;
	}

	span {
		color: var(--gray800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
	}
}
