.setting-content-title {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	gap: 20px;
	color: var(--greyscale-grey-800);
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 42px; /* 150% */
}
