.form {
	.input--success .input-item {
		border: 1px solid var(--border-main-color);
	}

	.input-notify__text {
		font-weight: 400;
		font-size: 12px;
		line-height: 180%;
		color: #e04141;
		margin-left: 3px;
	}

	.input-notify__char {
		display: none;
	}

	.authorization {
		position: relative;
		width: 100%;
		max-width: 421px;
		margin: auto;
		padding: 25px 0 40px;

		.show-pass {
			top: 25px;
			transform: inherit;
		}
	}

	.show-pass {
		top: 24px;
	}

	.input--error {
		.input-item {
			border-width: 1px;
			color: #e04141;
		}
	}

	.grecaptcha-badge {
		display: none;
	}

	.additional {
		position: relative;
		min-height: 327px;

		&__icon {
			width: 60px;
		}
	}

	.google-2fa-button {
		font-weight: normal;
		font-size: 16px;
		line-height: 1.3;
		color: #777e90;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.google-2fa-input {
		padding-top: 10px;
	}

	.google-2fa-icon {
		display: inline-block;
		width: 20px;
		margin-left: 5px;
		transition: all 0.3s linear;

		&--rotate {
			transform: rotate(180deg);
			transition: all 0.3s linear;
		}
	}

	.popup-window {
		&__close {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.captcha-modal {
		z-index: 1;
	}

	.additional__title.email-confirm-check {
		font-size: 30px;
	}

	textarea.input-item {
		padding: 12px 16px;
		min-height: 96px;
	}
}

.react-tel-input-wrapper {
	position: relative;

	.react-tel-input {
		position: absolute;
		top: 0;
		z-index: 5;
		height: 44px;
		width: 117px;
	}

	.special-label {
		display: none;
	}

	.flag-dropdown {
		width: 100%;
		border-radius: 8px 0 0 8px;
		background: var(--bg-color2);
		border-right: 1px solid var(--border-main-color);
		height: calc(100% - 2px);
		top: 1px;
		left: 1px;
	}

	.selected-flag {
		width: 100%;
		margin-top: 1px;
		padding: 0 0 0 13px;
	}

	.form-control {
		position: absolute;
		z-index: 10;
		left: 45px;
		top: 50%;
		padding: 0;
		width: 50px;
		border: none;
		pointer-events: none;
		background: transparent;
		transform: translateY(-50%);
	}

	.selected-flag .arrow,
	.selected-flag:focus .arrow {
		left: 84px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 6px solid var(--text-color2);
	}

	.selected-flag .arrow.up,
	.selected-flag:focus .arrow.up {
		margin-top: -2px;
		border-top: unset;
		border-bottom: 6px solid var(--text-color2);
	}

	.input-item {
		padding-left: 130px;
	}
}

.select-input {
	border: 0;
	pointer-events: none;
	background: transparent;
	width: 100%;
	font: inherit;
	color: inherit;

	&::placeholder {
		color: #b6b6b6;
		font-size: 14px;
	}
}

.select__custom {
	position: absolute;
	background: #fff;
	width: calc(100% - 40px);
}

.addon-right {
	input {
		border-radius: 8px 0 0 8px;
	}

	.input-item-info {
		border-left: none;
	}
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	border: none;
}

/*.react-datepicker .react-datepicker__navigation--previous {
	top: 0;
	left: 18px;
}

.react-datepicker .react-datepicker__navigation--next {
	top: 0;
	right: 18px;
}

.react-datepicker .react-datepicker__month {
	width: 216px;
}*/

.form-body.wallet-operations-item .select-block {
	margin-bottom: 24px;
}

.amount-input {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.select__drop .select__drop-scroll {
	max-height: 320px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.element---uppercase {
	text-transform: uppercase;
}

.input__notification .input__notification---span-color {
	color: #777e90;
}

.select__drop.select__drop---top {
	top: calc(100% + 3px);
}

.select--type3 .select__drop.select__drop---top {
	top: 48px;
}

.select.disabled {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: #fafafa;
		border-radius: 8px;
	}
}

.input-item--twofa {
	margin-bottom: 30px;
}

.form-item--twofa {
	margin-top: 20px;
}

.enable-2fa-text.twofa-title {
	margin-top: 30px;
}

.input-item--padding-bottom {
	margin-bottom: 15px !important;
}

.country-select .search-emoji::after {
	display: inline !important;
	visibility: visible;
	position: absolute;
	top: 30px;
	left: 30px;
	font-size: 15px;
	content: url('../../inline-svg/search.svg');
}

.wallet-operations .table-filter-left {
	.select-block {
		min-width: 130px;
	}
	.DatePicker {
		width: auto;
	}
}

.DatePicker .react-datepicker-popper {
	z-index: 5;
}

.input__notification button {
	color: #4376fe;
	font-size: 12px;
	display: inline-block;
}

.totp .input__notification--type2 a {
	font-size: 14px;
}

.value-formated {
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	padding: 0 15px;
	pointer-events: none;
	span {
		padding: 3px;
	}
}
