.payment-methods-header {
	padding: 16px 24px;
	min-height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	border-radius: 0;
	background: transparent;
	border-bottom: 1px solid var(--gray100);

	&__title {
		color: var(--gray800);
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&__actions {
		display: flex;
		gap: 10px;
	}
}
