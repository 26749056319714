.trade-wallet-balances {
	background: #fff;
	padding: 0;
	text-align: left;
	border-radius: 14px;
	border: 1px solid var(--gray100);

	&__tabs-wrap {
		padding: 24px 24px 16px 24px;
	}

	&__tabs {
		padding: 4px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		border-radius: 12px;
		background: #f1f7fc;
	}

	&__tab {
		color: var(--gray700);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 36px;
		padding: 4px 20px;

		&.active {
			font-weight: 600;
			color: var(--blue600);
			border-radius: 8px;
			background: #fff;
			box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.1);
		}
	}
}
