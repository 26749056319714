.instant-exchange-from-to {
	margin-bottom: 16px;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: stretch;
	gap: 8px;
	border: 0;
	border-radius: 0;

	&__item {
		padding: 10px;
		border-radius: 10px;
		background: var(--primary-blue-50);

		> p {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 10px;
		}

		.coin {
			display: flex;
			align-items: flex-start;
			gap: 10px;

			&__icon {
				position: relative;
				min-width: 42px;
				width: 42px;
				height: 42px;
				display: block;
			}

			&__curr {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}

			&__code {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-transform: uppercase;
			}

			&__name {
				color: var(--text-secondary);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
