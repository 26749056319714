.tabs-buttons {
	display: inline-flex;

	&--type2 {
		display: inline-flex;
		border-radius: 10px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		padding: 4px;

		a,
		button {
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--greyscale-grey-700);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;

			@media only screen and (min-width: 768px) {
				width: 212px;
				height: 34px;
			}

			@media only screen and (max-width: 767.98px) {
				min-height: 34px;
				padding: 2px 10px;
			}

			&.active {
				background: var(--blue100);
				color: var(--primary-blue-400);
			}
		}
	}

	&.tabs-buttons---border {
		border-radius: 12px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		padding: 4px;
	}

	a,
	button {
		border-radius: 8px;
		background: transparent;
		width: 180px;
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--greyscale-grey-500);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&.active {
			color: var(--primary-blue-400);
			font-weight: 600;
			background: var(--primary-blue-100);
			box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.1);
		}
	}
}
