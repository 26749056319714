.setting-content-body-item {
	&--type3 {
		border-radius: 10px;
		background: var(--primary-blue-50);
		border-color: var(--primary-blue-50);
		max-width: 580px;
		width: 100%;
		padding: 20px;
	}

	&--600 {
		max-width: 600px;
	}
}
