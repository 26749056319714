.instant-trade-history-section {
	padding: 30px 0 0 0;

	&__title-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	&__title {
		color: var(--gray800);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}
