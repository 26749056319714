.pagination {
	display: flex;
	flex-wrap: wrap;
	margin: 0;

	&__arrow {
		border-radius: 6px;
		background: var(--gray300);
		position: relative;

		&:hover {
			a:before {
				background-color: #fff;
			}
		}

		&.disabled {
			a:before {
				background-color: #e4e4e4;
			}
		}

		&--prev {
			margin-right: 16px;

			a:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.668 14.1693L7.85486 10.3562C7.65959 10.1609 7.65959 9.84431 7.85486 9.64905L11.668 5.83594' stroke='%23E4E4E4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px 20px;
				background-color: #212c63;
				position: absolute;
				top: calc(50% - 10px);
				left: calc(50% - 10px);
			}
		}

		&--next {
			margin-left: 16px;

			a:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.33203 5.83073L12.1451 9.64384C12.3404 9.8391 12.3404 10.1557 12.1451 10.3509L8.33203 14.1641' stroke='%23212C63' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px 20px;
				background-color: #212c63;
				position: absolute;
				top: calc(50% - 10px);
				left: calc(50% - 10px);
			}
		}
	}

	li {
		padding: 0;

		&.active > a,
		&.active > button,
		&:not(.disabled):hover > a,
		&:not(.disabled):hover > button {
			background: var(--blue600) !important;
		}

		a,
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			min-width: 32px;
			height: 32px;
			min-height: 32px;
			margin: 0;
			padding: 0;
			border: 0;
			border-radius: 6px;
			background: transparent;
			color: #777e90;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			transition: var(--transition-base);
		}
	}
}
