.workspace {
	padding: 20px;
	background: #ffffff;
	position: relative;
	justify-content: flex-start;
	.login-form {
		gap: 17px;
		margin-top: 73px;
	}
		.wallet-operations-header__back {
			left: 16px;
			top: 16px;
			position: absolute;
		}
	.login-form__title {
		line-height: 2;
		span {
			font-family: Euclid Circular A  ;
			font-size: 16px ;
			font-weight: 400  ;
			line-height: 25.6px  ;
			text-align: center  ;
			color: #636363;
		}
	}
	.workspace-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.workspace-item {
		display: flex;
		align-items: center;
		border: 1px solid #E4E4E4;
		width: 400px;
		height: 56px;
		border-radius: 10px;
		margin-bottom: 8px;
		cursor: pointer;
		&:last-child {
			margin-bottom: 0;
		}

		.workspace-icon {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
			margin-right: 10px;
			margin-left: 15px;
			p {
				text-transform: uppercase;
				font-family: Euclid Circular A;
				font-size: 16px;
				font-weight: 400;
				line-height: 20.29px;
				text-align: left;
			};

			&.blue {
				background: #30A2E4;
			}

			&.orange {
				background: #E49130;

			}

			&.purple {
				background: #E430BC;

			}
		}
		span {
			font-family: Euclid Circular A;
			font-size: 14px;
			font-weight: 500;
			line-height: 25.2px;
			text-align: left;
			color: #141414;

		}
	}
}

// sidebar
.sidebar-nav-item {
	.workspace {
		padding: 0;
		background: none;
		position: relative;
		border-bottom: 1px solid #4A4E82;
		.active {
			background: #4A4E82;

			span {
				font-family: Euclid Circular A;
				font-size: 16px !important;
				font-weight: 600 !important;
				line-height: 20.29px !important;
				text-align: left !important;
				color: #FFFFFF !important;
			};
		}
		img {
			position: absolute;
			right: 15px;
		}
		.workspace-item {
			width: 100%;
			border: none;
			span {
				font-family: Euclid Circular A;
				font-size: 16px;
				font-weight: 400;
				line-height: 20.29px;
				text-align: left;
				color: #FFFFFF;
			};
		}
		.workspace-item:hover {
			background: #4A4E82;
			transition: var(--transition-base);
			//span {
			//	font-size: 16.1px;
			//	font-weight: 600;
			//	transition: var(--transition-base);
			//}
		}
	}
}
@media screen and (max-width: 560px) {
	.workspace .workspace-item {
		width: 100%;
	}
}
