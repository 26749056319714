.table--your-account .tr {
	-ms-grid-columns: 1fr 1fr .2fr;
	grid-template-columns: 1fr 1fr .2fr;
}
@media (max-width: 1084px) {
	.table--your-account .tr {
		-ms-grid-columns: 1fr 1fr .5fr;
		grid-template-columns: 1fr 1fr .5fr;
	}
	.table--your-account {
		.td:last-child {
			flex-direction: column !important
		}
	}
}

.table--your-account .table-header .tr .td > p {
	border-radius: 6px;
	background: var(--greyscale-grey-10);
	padding: 2px 10px;
	height: 34px;
	display: flex;
	align-items: center;
	color: var(--greyscale-grey-600);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.table--your-account {
	min-height: 60vh;
	.tr {
		border-bottom: 1px solid #F2F2F2;
	}
	.table-body {
		.td:last-child {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.table-header .tr {
		border-bottom: none;
	}
	.td__text {
		//styleName: Body/Body4 14 Regular;
		font-family: Euclid Circular A;
		font-size: 14px;
		font-weight: 400;
		line-height: 22.4px;
		text-align: left;
		color: var(--gray800);
	}
	.td__more {
		//styleName: Body/Body6 12 Regular;
		font-family: Euclid Circular A;
		font-size: 12px;
		font-weight: 400;
		line-height: 15.22px;
		text-align: left;
		color: var(--text-color2);
	}
	.td {
		padding: 17px 6px;
	}
	.td__wrap {
		margin-left: 6px;
	}
	.btn--icon {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		border: 1px solid #a8dbf9;
		border: 1px solid var(--blue200);
		background: #d4edfc;
		background: var(--blue100);
		box-shadow: 0 2px 6px 0 rgba(33, 44, 99, .09);
		width: 38px;
		min-width: 38px;
		height: 38px;
		min-height: 38px;
		padding: 0;
		img {

		}
	}
}
.your-account {
	.table-block .pagination-block {
		border-top: none;
	}
}

@media (max-width: 1084px) {
	.your-account {
		.table-footer {
			display: flex;
			flex-direction: column;
		}
	}
}
