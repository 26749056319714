.td {
	&--nowrap {
		white-space: nowrap;
	}
}

.td--timestamp {
	.td--timestamp-date {
		display: block;
	}

	.td--timestamp-time {
		display: block;
		font-size: 12px;
		color: #777e90;
		margin-top: 5px;
	}
}

.payment-methods-item > span {
	word-break: break-all;
}

.table--wallet {
	.table-header .tr {
		border-bottom: none;
	}
}
