.payment-methods-body-plug {
	margin: 52px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	color: var(--greyscale-grey-400);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: center;
}
