.verification-upload {
	padding: 16px;
	border-radius: 10px;
	border: 1px dashed var(--greyscale-grey-100);
	background: var(--additional-white);

	@media only screen and (min-width: 768px) {
		height: 100%;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		cursor: pointer;

		@media only screen and (min-width: 768px) {
			height: 100%;
		}

		@media only screen and (max-width: 767.98px) {
			min-height: 200px;
		}
	}

	&__button-content {
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 16px;
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		background: transparent;
		border-radius: 0;

		b {
			color: var(--primary-blue-400);
			font-weight: 600;
		}
	}
}
