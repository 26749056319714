.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 81px;
	border-bottom: 1px solid var(--gray100);
	padding: 0 16px;

	@media only screen and (min-width: 768px) {
		padding: 0 32px;
	}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		gap: 16px;
	}

	&__logo {
		display: block;

		@media only screen and (min-width: 768px) {
			border-right: 1px solid var(--greyscale-grey-100);
			padding-right: 24px;
		}

		img {
			display: block;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		@media only screen and (max-width: 575.98px) {
			flex-direction: column;
			align-items: stretch;
		}
	}
}
