.react-datepicker-popper[data-placement^='bottom'] {
	padding-top: 5px;
	top: 100% !important;
	transform: none !important;
	width: 100%;
}

.react-datepicker {
	border-radius: 6px;
	border: 1px solid var(--blue200);
	background: var(--additional-white);
	font-family: var(--font-sans) !important;
	font-size: 12px;
	color: var(--greyscale-grey-500);
	box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 8px rgba(96, 97, 112, 0.16);
	position: relative;
	padding: 8px;
	width: 100%;
	display: inline-block;

	&__current-month {
		color: var(--gray800);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%; /* 22.4px */
		margin-bottom: 14px;
	}

	&__navigation {
		border-radius: 6px;
		background: var(--primary-blue-400);
		width: 28px;
		min-width: 28px;
		height: 28px;
		min-height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 5px;

		&--previous {
			left: 6px;
		}

		&--next {
			right: 6px;
		}
	}

	&__navigation-icon {
		background-size: 16px 16px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		width: 16px;
		min-width: 16px;
		height: 16px;
		min-height: 16px;
		display: block;

		&--previous {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.33203 4.66927L6.35225 7.64905C6.15699 7.84431 6.15699 8.1609 6.35225 8.35616L9.33203 11.3359' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}

		&--next {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66797 4.66927L9.64775 7.64905C9.84301 7.84431 9.84301 8.1609 9.64775 8.35616L6.66797 11.3359' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			position: static;
		}
	}

	&__month-container {
		float: none;
	}

	&__day-names {
		display: none;
	}

	&__month {
		margin: 0;
	}

	&__week {
		display: flex;
		justify-content: space-between;
		//gap: 8px;

		&:not(:last-of-type) {
			margin-bottom: 8px;
		}
	}

	&__day {
		width: 28px;
		height: 28px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background: var(--primary-blue-50);
		color: var(--primary-blue-400);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 19.2px */

		&:hover {
			border-radius: 4px;
			background: var(--blue400);
			color: var(--additional-white);
		}

		&--disabled,
		&--outside-month {
			background: var(--greyscale-grey-10);
			color: var(--greyscale-grey-500);

			&:hover {
				background: var(--greyscale-grey-10);
				color: var(--greyscale-grey-500);
			}
		}
	}

	&__day--range-start {
		background: var(--blue600) !important;
		color: var(--additional-white) !important;

		&.react-datepicker__day--in-range {
			border-radius: 4px !important;
		}
	}

	&__day--range-end {
		background: var(--blue600) !important;
		color: var(--additional-white) !important;

		&.react-datepicker__day--in-range {
			border-radius: 4px !important;
		}
	}

	&__day--in-range {
		background: var(--blue600) !important;
		color: var(--additional-white) !important;

		+ .react-datepicker__day--in-range:before {
			display: none;
		}
	}

	&__helpers {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__helper-input {
		width: 108px;
		height: 28px;
		border-radius: 6px;
		background: var(--greyscale-grey-10);
		border: 1px solid var(--greyscale-grey-10);
		text-align: center;
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		&::placeholder {
			opacity: 1;
			color: var(--greyscale-grey-200);
			font-size: 12px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
		}
	}

	&__helper-arrow {
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0013 12.6693L14.668 8.0026L10.0013 3.33594L9.3013 4.03594L12.768 7.5026H1.33463V8.5026H12.768L9.3013 11.9693L10.0013 12.6693Z' fill='%230071AB'/%3E%3C/svg%3E%0A");
		width: 16px;
		min-width: 16px;
		height: 16px;
		min-height: 16px;
	}
}
