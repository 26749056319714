.wallet-content-header {
	@media only screen and (max-width: 576px) {
		flex-direction: column;
		align-items: flex-start;
	}

	&__title {
		color: var(--gray800);
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
	}

	&__buttons {
		gap: 10px;

		@media only screen and (max-width: 576px) {
			flex-direction: column;
		}
	}
}
