.error-block {
	margin-bottom: 20px;
	padding: 8px 10px;
	display: flex;
	align-items: flex-start;
	gap: 6px;
	border-radius: 4px;
	background: var(--transparent-red);

	&__icon {
		min-width: 20px;
		width: 20px;
		height: 20px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99298 13.773C10.1588 13.773 10.2993 13.7169 10.4144 13.6047C10.5295 13.4925 10.5871 13.3535 10.5871 13.1876C10.5871 13.0218 10.531 12.8813 10.4188 12.7662C10.3066 12.6511 10.1676 12.5935 10.0018 12.5935C9.83591 12.5935 9.69542 12.6496 9.58031 12.7618C9.4652 12.874 9.40765 13.013 9.40765 13.1789C9.40765 13.3447 9.46374 13.4852 9.57594 13.6003C9.68812 13.7154 9.82713 13.773 9.99298 13.773ZM9.45573 11.0615H10.539V6.06148H9.45573V11.0615ZM10.0045 17.5807C8.9613 17.5807 7.9788 17.3833 7.05702 16.9886C6.13523 16.5939 5.32841 16.0508 4.63656 15.3592C3.9447 14.6677 3.40132 13.8615 3.00642 12.9408C2.61151 12.02 2.41406 11.0361 2.41406 9.98923C2.41406 8.94231 2.61142 7.96144 3.00615 7.0466C3.40087 6.13176 3.94399 5.32841 4.63552 4.63656C5.32706 3.9447 6.13322 3.40132 7.054 3.00642C7.97478 2.61151 8.95862 2.41406 10.0055 2.41406C11.0524 2.41406 12.0333 2.61142 12.9481 3.00615C13.863 3.40087 14.6663 3.94399 15.3582 4.63552C16.05 5.32706 16.5934 6.1316 16.9883 7.04913C17.3832 7.96665 17.5807 8.94702 17.5807 9.99023C17.5807 11.0335 17.3833 12.016 16.9886 12.9377C16.5939 13.8595 16.0508 14.6663 15.3592 15.3582C14.6677 16.05 13.8632 16.5934 12.9456 16.9883C12.0281 17.3832 11.0477 17.5807 10.0045 17.5807ZM9.99738 16.4974C11.8029 16.4974 13.3377 15.8654 14.6015 14.6015C15.8654 13.3377 16.4974 11.8029 16.4974 9.99738C16.4974 8.19182 15.8654 6.6571 14.6015 5.39321C13.3377 4.12932 11.8029 3.49738 9.99738 3.49738C8.19182 3.49738 6.6571 4.12932 5.39321 5.39321C4.12932 6.6571 3.49738 8.19182 3.49738 9.99738C3.49738 11.8029 4.12932 13.3377 5.39321 14.6015C6.6571 15.8654 8.19182 16.4974 9.99738 16.4974Z' fill='%23FE0000'/%3E%3C/svg%3E%0A");

		svg {
			display: none;
		}
	}

	p {
		color: var(--gray800);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 21px */
	}
}
