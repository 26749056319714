.create-account-section {
	.setting-content {
		padding: 0 36px 36px 36px;
	}
	.setting-content-title {
		display: flex;
		justify-content: center;
	}
	.settings-2fa-item--type2 .settings-2fa-item .settings-2fa-item--type2 {
		grid-template-columns: (1fr);
	}
}
.setting-content {
	.settings-2fa-item {
		grid-template-columns: (1fr);
	}
}
