.input-file {
	padding: 16px;
	border-radius: 10px;

	background: var(--additional-white);
	margin-bottom: 30px;
	&.active {
		border: 1px solid var(--greyscale-grey-100);

		background: #f5f5f5;
	}
	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		cursor: pointer;
	}

	&.drop-file-preview__item {
		img {
			width: 32px;
		}
	}
}

.input-file-preview {
	margin-bottom: 16px;
	display: flex;
	flex-flow: row wrap;
	grid-gap: 8px;
	gap: 8px;
	&__item {
		position: relative;
		margin-right: 0;
		border-radius: 10px;
		border: 1px solid var(--primary-blue-200);
		background: var(--additional-white);
		width: 64px;
		min-width: 64px;
		height: 83px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			width: 32px;
		}
	}
	&__name {
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		width: 52px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 8px;
	}
}

