.wallet-operations {
	background: var(--gray300);

	@media only screen and (min-width: 760px) {
		padding: 40px;
	}

	@media only screen and (min-width: 1200px) {
		padding: 40px 40px 40px 30px;
	}

	&__form-wrap {
		margin-top: 40px;
		padding: 24px;
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: #fff;

		.form {
			max-width: 492px;
			width: 100%;
			padding: 20px;
			border-radius: 10px;
			background: var(--primary-blue-50);
		}
	}
	.input-notify p {
		font-weight: 400;
		font-size: 12px;
		line-height: 180%;
		color: #e04141;
	}
}
