// ==========================================:
.Toastify {
	&__toast-container {
		width: unset;
		max-width: 370px;
		min-width: 320px;
	}
	&__toast {
		background-color: #fff;
		padding: 0;
	}
	&__toast-icon {
		display: none;
	}
	&__toast-body {
		margin: 0;
		padding: 0;
		color: #000;
	}
	&__close-button {
		padding: 6px;
		svg path {
			fill: #000000;
		}
	}
}

.toastify-component {
	display: flex;
	height: 100%;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 82px;
		&--error {
			background-color: #b8362d;
			padding: 0 20px;
		}
		&--info {
			background-color: #4bc0b1;
			padding: 0 20px;
		}
		&--success {
			background-color: #3b97d3;
			padding: 0 20px;
		}
	}

	&__title {
		color: #4c4c4c;
		font-weight: 600;
		font-size: 16px;
		line-height: 1.3;
		margin-bottom: 5px;
	}

	&__text {
		color: #4c4c4c;
		font-size: 14px;
		line-height: 1.3;
	}

	&__content {
		width: 100%;
		padding: 10px;
		color: #4c4c4c;
	}
}

button {
	&.link--type2 {
		text-decoration: underline !important;
	}
	&.link {
		color: var(--accent-color) !important;
	}
}
