.bonus-system-item {
	padding: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: 1.1fr 0.7fr 1fr;
	gap: 8px;
	border-radius: 8px;
	background: var(--additional-white);
	border: 0;

	&__balance {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
