.trade {
	&__row {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		padding: 40px 0 0 0;

		@media only screen and (max-width: 992px) {
			grid-template-columns: repeat(1, 1fr);
			gap: 30px;
		}
	}

	&__box-heading {
		color: var(--gray800);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 20px;
	}
}
