.table-block {
	margin-bottom: 40px;

	&--mb-0 {
		margin-bottom: 0;
	}

	.pagination-block {
		border-top: 1px solid var(--gray100);
		margin: 0;
		padding: 30px 24px 24px 24px;
		justify-content: flex-end;
	}
}

.table-block--with-y-scroll {
	.table-footer {
		border-radius: 0 0 14px 14px;
		border: 1px solid var(--gray100);
		border-top: none;
		background: #fff;
		padding: 20px 24px 24px 24px;
	}
	.pagination-block {
		border-top: none;
		margin: 0;
		padding: 0;
		justify-content: flex-end;
	}
	.select-page {
		.select--per-page-wrapper {
			color: var(--blue600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 16px;

			.select {
				width: 80px;
				height: 32px;
				border-radius: 6px;
				border: 1px solid var(--greyscale-grey-100);
				background: var(--greyscale-grey-10);

				&.active {
					border: 1px solid var(--primary-blue-200);
					background: var(--additional-white);

					.select__current-arrow {
						transform: rotate(180deg);
					}
				}
			}

			.select__current {
				padding: 0 10px;
				color: var(--greyscale-grey-600);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.select__current-arrow {
				margin-left: 0;
			}

			.select__drop {
				top: unset;
				left: 0;
				min-width: 120px;
				bottom: calc(100% + 5px);
				border-radius: 6px;
				border: 1px solid var(--primary-blue-200);
				background: var(--additional-white);
				box-shadow: 0 4px 15px 0 rgba(33, 44, 99, 0.09);
				padding: 2px;

				ul > li > button {
					padding: 5px 10px;
					border-radius: 4px;
					color: var(--greyscale-grey-500);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 160%; /* 22.4px */
					background: transparent;

					&:hover,
					&.active {
						background: var(--primary-blue-50);
						color: var(--greyscale-grey-800);
						font-weight: 500;
					}
				}
			}
		}
	}
	.button-wrap {
		display: flex;
		gap: 8px;
	}
}

