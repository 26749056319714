.bonus-title {
	color: var(--greyscale-grey-800);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;

	&__count {
		color: var(--primary-blue-400);
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%; /* 38px */
	}
}
