.setting-content-back {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;
	border-radius: 6px;
	border: 1px solid var(--primary-blue-200);
	background: var(--primary-blue-100);
	box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);

	&:before {
		content: '';
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9 19L2 12L9 5L10.05 6.05L4.85 11.25H22V12.75H4.85L10.05 17.95L9 19Z' fill='%230071AB'/%3E%3C/svg%3E");
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
	}

	svg {
		display: none;
	}
}
